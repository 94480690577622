import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import axios from 'axios';

import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Blocks from '../../blocks';
import Seo from '../../seo';
import { getPreviewPage } from '../../../state/app';
import { Image } from '../../BlockLibrary/Common/Image';
import { RichText } from '../../richTextOptions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ShareSocials from '../../BlockLibrary/Common/ShareSocials';
import { FacebookShareButton } from 'react-share';
import { graphql, useStaticQuery } from 'gatsby';
import { titleCase } from '../../utilities';

const getData = graphql`
     query YearNomineesWackyPreview {
          allContentfulWackyPetCategory(sort: { order: ASC, fields: slug }) {
               nodes {
                    slug
               }
          }
     }
`;

const WackyStoryPreview = ({ allStories, isLoading }) => {
     const today = new Date();

     const dataQuery = useStaticQuery(getData);
     const slugs = dataQuery.allContentfulWackyPetCategory.nodes;

     let getNewYear = today.getFullYear();

     if (slugs && slugs.length > 0) {
          getNewYear = Math.max(...slugs.map((slug) => parseInt(slug.slug.slice(0, 4), 10)));
     }

     const story = useSelector((state) => getPreviewPage(state));
     // const seoTitleTag = story.fields.seoTitleTag ? story.fields.seoTitleTag : `${story.fields.title} | ${story.fields.shortDescription} | Best Pet Names`;
     // const seoMetaDescription = story.fields.seoMetaDescription ? story.fields.seoMetaDescription : story.fields.shortDescription;
     var tabInd = 0;
     const { category } = story.fields;
     var categorySlug = '/wackypetnames/' + category.fields.slug + '/';

     let isVotingOn =
          (story.fields.turnOnVotingForThisStory || (category.fields.turnOnVotingForThisCategory && story.fields.turnOnVotingForThisStory !== false)) &&
          category.fields.slug.includes(getNewYear); // story option overrides category option

     let petType = category.fields.slug.toLowerCase().includes('dog') ? 'dog' : category.fields.slug.toLowerCase().includes('cat') ? 'cat' : 'exotic';
     const COOKIE_NAME = petType === 'dog' ? 'dog_voting_visitor' : petType === 'cat' ? 'cat_voting_visitor' : 'exotic_voting_visitor';
     const DAYS_TO_EXPIRE = 1;
     const [isVoted, setIsVoted] = useState(!!Cookies.get(COOKIE_NAME));
     const petName = story.fields.petName || story.fields.title;
     const imageStoryUrl = story.fields.image?.fields.file.url;
     const storyImage = story.fields.image.fields.file.url;
     const styleCardImage = {
          backgroundImage: `url(${storyImage})`
     };

     let votingButtonSet = true;
     let enabledText = '';
     let disabledText = '';
     let salesforceUrl = '';

     const replacePetName = useCallback((string) => {
          return string.replace(/##wackypetname##/gi, petName.toUpperCase());
     }, []);

     const [currentHref, setCurrentHref] = useState('');
     useEffect(() => {
          if (window) {
               setCurrentHref(window.location.href);
          }
     }, []);

     const guid = useCallback(() => {
          const s4 = () => {
               return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
          };
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
     }, []);

     if (story.fields.votingButton) {
          const storyVotingButton = story.fields.votingButton;

          enabledText = replacePetName(storyVotingButton.fields.enabledText);
          disabledText = replacePetName(storyVotingButton.fields.disabledText);
          salesforceUrl = storyVotingButton.fields.salesforceUrl;
     } else if (category.fields.votingButton) {
          const categoryVotingButton = category.fields.votingButton;

          enabledText = replacePetName(categoryVotingButton.fields.enabledText);
          disabledText = replacePetName(categoryVotingButton.fields.disabledText);
          salesforceUrl = categoryVotingButton.fields.salesforceUrl;
     } else {
          console.log('Please set voting button for the category which this story belongs to or for this story');
          votingButtonSet = false;
     }

     const handleClose = () => {
          setShow(false);
     };

     const [show, setShow] = useState(false);
     const handleShow = () => setShow(true);

     const PopupVoting = () => {
          return (
               <>
                    <>
                         <Modal className="Popupsuccess popup-wacky-voting" show={show} onHide={handleClose}>
                              <div className="group-content-popup">
                                   <div className="content-popup">
                                        <Modal.Body className="text">
                                             <strong>Thanks for voting for</strong>
                                             <h2>{story.fields.petName}</h2>
                                             {/* <p>You can come back and vote again for {story.fields.petName} once a day.</p>
                                             <p>Share who you voted for and enter to win [offer]!</p> */}
                                             <p>Vote once per day for your favorite kooky canine, funky feline and nutty exotic pet names now through Friday, April 12, 2024.</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                             <Button variant="primary">
                                                  <FacebookShareButton url={currentHref} appId="623395759254133">
                                                       Share this pet
                                                  </FacebookShareButton>
                                             </Button>
                                        </Modal.Footer>
                                   </div>
                                   <div className="spacing-popup"></div>
                              </div>
                              <div className="image-popup">
                                   <Image image={story.fields.image} />
                              </div>
                         </Modal>
                    </>
               </>
          );
     };

     const VotingButton = () => {
          if (!votingButtonSet) {
               return <></>;
          }

          const voting = (e) => {
               var votingPick = e.target.name;
               var ipAddr = '';

               axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((response) => {
                    if (response.status === 200) {
                         const myRegexp = /^(?:ip)=(.*)$/gm;
                         let responseString = response.data;
                         let match;

                         match = myRegexp.exec(responseString);
                         while (match != null) {
                              ipAddr = ipAddr.concat(match[1] + '\n');
                              match = myRegexp.exec(responseString);
                         }
                    } else {
                         ipAddr = guid();
                    }

                    let cookieCreateDate = dayjs();
                    let cookieExpireDate = cookieCreateDate.add(DAYS_TO_EXPIRE, 'day');
                    let cookieExpireTemp = cookieExpireDate.toISOString().slice(0, 10);

                    let cookieValue = {
                         ipAddr,
                         cookieExpireDate: cookieExpireDate.toString(),
                         votingChoice: petName
                    };

                    //set cookie
                    Cookies.set(COOKIE_NAME, JSON.stringify(cookieValue), { expires: DAYS_TO_EXPIRE });
                    setIsVoted(true);
                    handleShow();

                    if (salesforceUrl) {
                         var url =
                              salesforceUrl +
                              '&ipAddress=' +
                              ipAddr +
                              '&cookieExpireDate=' +
                              encodeURIComponent(cookieExpireTemp) +
                              '&votingPick=' +
                              encodeURIComponent(votingPick) +
                              '&votedSpecies=' +
                              petType;
                         axios.post(url).then((response) => {
                              // console.log(response);
                         });
                         // window.location.href = url;
                    }
               });
          };

          return (
               <>
                    {isVoted ? (
                         <>
                              <PopupVoting />
                              <button className="btn-voting disabled" disabled>
                                   <img loading="lazy" src="/images/icons/Icon-Dog2022.svg" alt="IconDog" />
                                   {disabledText}
                              </button>
                         </>
                    ) : (
                         <>
                              <PopupVoting />
                              <button className="btn-voting" name={petName} onClick={voting}>
                                   <img loading="lazy" src="/images/icons/Icon-Dog2022.svg" alt="IconDog" />
                                   {enabledText}
                              </button>
                         </>
                    )}
               </>
          );
     };

     const getDefaultSeo = () => {
          const year = story.fields.category?.fields?.slug?.split('-')?.[0];
          let petType = story.fields.category?.fields?.slug?.split('-')?.[1];

          if (petType === 'exotic') {
               petType = 'exotic pet';
          }

          const titleCasePetType = titleCase(petType);

          let defaultSeoTitle = `${year} Wacky ${titleCasePetType} Name Nominees | ${story.fields.petName}`;
          let defaultSeoMetaDescription = `Click to meet ${story.fields.petName}, one of Nationwide's proud nominees for the coveted title of ${year} Wacky ${titleCasePetType} Names.`;

          return { defaultSeoTitle, defaultSeoMetaDescription };
     }

     const { defaultSeoTitle, defaultSeoMetaDescription } = getDefaultSeo();

     return (
          <>
               <Layout>
                    {isLoading && <div className="page-loading">Loading ...</div>}
                    <Seo
                         title={story.fields.seoTitleTag || defaultSeoTitle}
                         canonical={story.fields.seoCanonicalUrl}
                         description={story?.fields?.seoMetaDescription || defaultSeoMetaDescription}
                         robots={story.fields.robotsDirective}
                         image={story.fields.socialImage ? story.fields.socialImage : story.fields.featuredImage}
                    />
                    <Header header={story.fields.header} tabInd={(tabInd = tabInd + 1)}></Header>
                    <section className="wacky-story-information">
                         <div className="container">
                              <div className="row wacky-story-detail">
                                   <div className="col-lg-8 col-md-7 wacky-story-name">
                                        <div className="wacky-story-header">
                                             <div className="wacky-story-title">
                                                  <h1>{story.fields.title}</h1>
                                                  <p>{story.fields.shortDescription}</p>
                                                  {story.fields.wackySubTitle && <h3>{story.fields.wackySubTitle} </h3>}
                                             </div>
                                             <div className="wacky-story-image" style={styleCardImage}>
                                                  <Image image={story.fields.image} />
                                             </div>
                                        </div>
                                        <div className="wacky-story-content">
                                             {story.fields.body && <RichText data={story.fields.body} />}
                                             <div className="article-page-socials wacky-story-btn">
                                                  {isVotingOn && <VotingButton />}
                                                  <ShareSocials imageArticleUrl={imageStoryUrl} />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-4 col-md-5 wacky-story-menu">
                                        <div className="list-top-pet">
                                             <ul>
                                                  {allStories &&
                                                       allStories.map((val, i) => {
                                                            if (val.sys.id == story.sys.id) {
                                                                 return (
                                                                      <li key={i}>
                                                                           <a href={categorySlug + val.fields.slug + '/'} className="active">
                                                                                {val.fields.title}
                                                                           </a>
                                                                      </li>
                                                                 );
                                                            }
                                                            return (
                                                                 <li key={i}>
                                                                      <a href={categorySlug + val.fields.slug + '/'}>{val.fields.title}</a>
                                                                 </li>
                                                            );
                                                       })}
                                             </ul>
                                        </div>
                                   </div>
                                   <div className="col-lg-4 col-md-5 wacky-story-dropdown">
                                        <Dropdown>
                                             <Dropdown.Toggle>{story.fields.petName}</Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                  {allStories.map((option, i) => (
                                                       <Dropdown.Item
                                                            key={i}
                                                            href={categorySlug + option.fields.slug + '/'}
                                                            className={option.fields.slug === story.fields.slug ? 'active' : ''}
                                                       >
                                                            {option.fields.title}
                                                       </Dropdown.Item>
                                                  ))}
                                             </Dropdown.Menu>
                                        </Dropdown>
                                   </div>
                              </div>
                         </div>
                    </section>
                    {story.fields.rows && <Blocks blocks={story.fields.rows} tabInd={tabInd}></Blocks>}
                    <Footer footer={story.fields.footer} tabInd={(tabInd = tabInd + 1)}></Footer>
               </Layout>
          </>
     );
};

export default WackyStoryPreview;
