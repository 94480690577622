import React, { Component } from 'react';
import { connect } from 'react-redux';
import WackyStoryPreview from '../components/PreviewLibrary/WackyPetNames/WackyStoryPreview';
import { getPreviewPage } from '../state/app';
import Pusher from 'pusher-js';
import { setOmCode, setPhoneLocalStorage } from '../components/utilities';

const contentful = require('contentful');

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com',
     environment: process.env.GATSBY_CONTENTFUL_PREVIEW_ENVIRONMENT
});

class wackystoryPreview extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: true,
               sysId: this.props['*'],
               sysIdCategory: null,
               allStories: []
          };
     }
     componentDidMount() {
          const _this = this;
          const brower = typeof window !== `undefined`;
          _this.setState({ ..._this.state, loading: true });
          client
               .getEntries({
                    'sys.id': _this.state.sysId,
                    include: 10
               })
               .then((entries) => {
                    setTimeout(function() {
                         _this.setState({ ..._this.state, loading: false });
                    }, 500);
                    if (entries.total > 0) {
                         var test = entries.items[0].fields.category.sys.id;
                         _this.props.setPreviewPage(entries.items[0]);
                         if (brower) {
                              var om = entries.items[0].fields.trackingCode;
                              var phone = entries.items[0].fields.specificPhone;
                              setOmCode(om, _this.props.location.href);
                              setPhoneLocalStorage(phone, _this.props.location.href);

                              var getOmValue = localStorage.getItem('Petinsurance_OM');
                              var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
                              _this.props.setTrackingCode(getOmValue);
                              _this.props.setTrackingPhone(getPhoneValue);
                         }
                    }
                    client
                         .getEntries({
                              links_to_entry: test,
                              include: 10
                         })
                         .then((res) => {
                              if (res.total > 0) {
                                   res.items.forEach((item) => {
                                        this.setState({ allStories: this.state.allStories.concat(item) });
                                   });
                              }
                         })
                         .catch(console.error);
               })
               .catch(console.error);

          Pusher.logToConsole = true;

          var pusher = new Pusher('6e16fb2a37840ec116dc', {
               cluster: 'mt1',
               forceTLS: true
          });

          var channel = pusher.subscribe('my-channel');
          channel.bind('pageReload', function(data) {
               _this.setState({ ..._this.state, loading: true });
               client
                    .getEntries({
                         'sys.id': _this.state.sysId,
                         include: 10
                    })
                    .then((entries) => {
                         setTimeout(function() {
                              _this.setState({ ..._this.state, loading: false });
                         }, 1000);
                         if (entries.total > 0) {
                              _this.props.setPreviewPage(entries.items[0]);
                              if (brower) {
                                   var om = entries.items[0].fields.trackingCode;
                                   var phone = entries.items[0].fields.specificPhone;
                                   setOmCode(om, _this.props.location.href);
                                   setPhoneLocalStorage(phone, _this.props.location.href);

                                   var getOmValue = localStorage.getItem('Petinsurance_OM');
                                   var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
                                   _this.props.setTrackingCode(getOmValue);
                                   _this.props.setTrackingPhone(getPhoneValue);
                              }
                         }
                    })
                    .catch((err) => {});

               client
                    .getEntries({
                         links_to_entry: _this.state.sysId,
                         include: 10
                    })
                    .then((res) => {
                         if (res.total > 0) {
                              res.items.forEach((item) => {
                                   this.setState({ allStories: this.state.allStories.concat(item) });
                              });
                         }
                    })
                    .catch(console.error);
          });
     }
     render() {
          return <>{this.props.previewPage != null && <WackyStoryPreview allStories={this.state.allStories} isLoading={this.state.loading}></WackyStoryPreview>}</>;
     }
}
const mapStateToProps = (state) => {
     return {
          previewPage: getPreviewPage(state)
     };
};

const mapDispatchToProps = (dispatch) => {
     return {
          setPreviewIncludes: (includes) => dispatch({ type: `PREVIEW_STATE_INCLUDES`, payload: includes }),
          setPreviewPage: (page) => dispatch({ type: `PREVIEW_STATE_PAGE`, payload: page }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(wackystoryPreview);
